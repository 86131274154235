import { CommonModule, registerLocaleData } from '@angular/common';
import { Component } from '@angular/core';
import {
  Event,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HomeComponent } from '@pages/home/home.component';
import * as Sentry from '@sentry/angular-ivy';
import { AuthService } from '@services/auth.service';
import { getUserFullName } from '@utils/user';
import { KeycloakService } from 'keycloak-angular';
import { PfAppComponent, PfPageComponent, PfSideMenuComponent } from 'pf-ui';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { filter, switchMap, tap } from 'rxjs/operators';

import localeEn from '@angular/common/locales/en'; // Importation des données de localisation françaises
import localeFr from '@angular/common/locales/fr'; // Importation des données de localisation françaises
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData,
} from '@components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import { AgentService } from '@services/agent.service';
import { AppService } from '@services/app.service';
import { TenantService } from '@services/tenant.service';
import { AdminRoute, CandidateRoute } from '@utils/routes';
import { KeycloakProfile } from 'keycloak-js';
import { DialogService } from 'primeng/dynamicdialog';
import { from } from 'rxjs';
import {
  adminMenuItems,
  agentManageMenuItems,
  businessManageMenuItems,
  questionsMenuItems,
  seriesMenuItems,
  sessionsMenuItems,
  settingsMenuItems,
  statisticsMenuItems,
} from './app.routes';

export const TENANT_KEY = 'activeTenant';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    PfAppComponent,
    PfPageComponent,
    HomeComponent,
    PfSideMenuComponent,
    ToastModule,
    TranslateModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  adminView = false;
  candidateProviderId?: string;
  keycloakUserFullName?: string;
  headerDesktopTopMenuItems: MenuItem[] = [];
  headerDesktopBottomMenuItems: MenuItem[] = adminMenuItems;
  tenants: string[] = [];
  isHeaderDisplayed = this.appService.isHeaderDisplayed();
  agent = this.agentService.signalCurrent;

  getUserFullName = getUserFullName;

  constructor(
    private readonly authService: AuthService,
    public appService: AppService,
    private readonly dialog: DialogService,
    public keycloak: KeycloakService,
    private readonly router: Router,
    public translate: TranslateService,
    public agentService: AgentService,
    private readonly config: PrimeNGConfig,
    private readonly tenantService: TenantService,
  ) {
    this.tenantService.initialize();
    this.router.events
      .pipe(
        filter((event) => this.filterRouterEvents(event)),
        tap((event: Event) => {
          this.setupAgentView(event);
        }),
        switchMap(() => from(this.keycloak.loadUserProfile())),
        tap((keycloakProfile: KeycloakProfile) => {
          this.keycloakUserFullName = this.getUserFullName(keycloakProfile);
        }),
      )
      .subscribe(() => {
        this.setHeaderDesktopTopMenuItems();
        this.appService.updateHeaderDisplay(true);
      });
  }

  public filterRouterEvents = (event: Event): boolean => {
    const isNavigationEvent = event instanceof NavigationEnd;
    const currentEvent = event as NavigationEnd;
    const isCandidateEvent = [
      CandidateRoute.Home,
      CandidateRoute.Login,
      '',
    ].includes(currentEvent.url?.replace('/', ''));
    return isNavigationEvent && !isCandidateEvent;
  };

  public setupAgentView(event: Event): void {
    this.handleSelectedLanguageChange('fr');
    this.setUserView(event as NavigationEnd);

    const agent = this.agent();
    if (agent == null) {
      this.router.navigate([AdminRoute.Home]);
    }

    this.setAdminMenuItems();
    Sentry.setUser(agent);
  }

  public logoutItem: MenuItem = {
    label: 'Déconnexion',
    icon: 'pi pi-sign-out',
    command: () => {
      this.openConfirmLogout();
    },
  };

  setAdminMenuItems(): void {
    this.headerDesktopBottomMenuItems = adminMenuItems;
    const menuItems = [
      sessionsMenuItems,
      seriesMenuItems,
      questionsMenuItems,
      statisticsMenuItems,
    ];

    menuItems.forEach((menuItem) => {
      this.headerDesktopBottomMenuItems.push(menuItem(this.agent()));
    });

    const settings: MenuItem = settingsMenuItems(this.agent());
    settings.items = [
      agentManageMenuItems(this.agent()),
      businessManageMenuItems(this.agent()),
    ];

    this.headerDesktopBottomMenuItems.push(settings);
  }

  handleSelectedLanguageChange(lang: string): void {
    this.translate
      .use(lang)
      .pipe(
        switchMap(() => {
          return this.translate.getTranslation(lang);
        }),
      )
      .subscribe((data) => {
        this.config.setTranslation(data.primeng);
        switch (lang) {
          case 'fr':
          case 'ty':
            registerLocaleData(localeFr); // Enregistrement des données de localisation
            break;
          case 'en':
            registerLocaleData(localeEn);
        }
      });
  }

  openConfirmLogout(): void {
    const dialog = this.dialog.open(SpecificConfirmDialogComponent, {
      data: {
        label: 'Souhaitez-vous vous déconnecter ? ',
        title: 'Déconnexion',
        confirmLabel: 'Annuler',
        cancelLabel: 'Confirmer',
      } satisfies SpecificConfirmDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    dialog.onClose.subscribe((cancel?: boolean) => {
      if (cancel === false) {
        this.keycloak.logout();
      }
    });
  }

  get username(): string | undefined {
    return this.adminView
      ? this.keycloakUserFullName
      : this.candidateProviderId;
  }

  public setHeaderDesktopTopMenuItems(): void {
    this.headerDesktopTopMenuItems = [
      {
        label: this.username,
        icon: 'pi pi-user',
      },
    ];

    if (this.adminView) {
      this.headerDesktopTopMenuItems.push(this.logoutItem);
    }
  }

  public hasMultipleTenantsAccess(): boolean {
    return this.tenants.length > 0;
  }

  public setUserView(event: NavigationEnd): void {
    const currentUrl = event.url;

    if (currentUrl.includes('admin')) {
      this.adminView = true;
    }

    if (!currentUrl.includes('login') && currentUrl.includes('candidate')) {
      this.setCandidateProviderId();
      this.adminView = false;
    }
  }

  public setCandidateProviderId(): void {
    const isCandidateLoggedIn = this.authService.checkCandidateToken();
    if (!isCandidateLoggedIn) return;

    const { providerId } = this.authService.getProviderIdAndSessionCode();
    this.candidateProviderId = providerId;
  }
}
