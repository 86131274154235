<pf-app
  headerTitle="Direction des Transports Terrestres"
  [showHeader]="isHeaderDisplayed()"
  [showFooter]="false"
  [footerTopLinks]="[]"
  [headerDesktopTopMenuItems]="headerDesktopTopMenuItems"
  [headerDesktopBottomMenuItems]="adminView ? headerDesktopBottomMenuItems : []"
  [availableLanguageCodes]="['fr']"
  (selectedLanguageChange)="handleSelectedLanguageChange($event)"
  [ngClass]="adminView ? '' : 'hide-languages'"
>
  <p-toast [preventOpenDuplicates]="true" />
  <pf-page class="w-full">
    <router-outlet></router-outlet>
  </pf-page>
</pf-app>
